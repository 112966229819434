const actions = {
  SINGLE_PROJECT_BEGIN: 'SINGLE_PROJECT_BEGIN',
  SINGLE_PROJECT_SUCCESS: 'SINGLE_PROJECT_SUCCESS',
  SINGLE_PROJECT_ERR: 'SINGLE_PROJECT_ERR',

  FILTER_PROJECT_BEGIN: 'FILTER_PROJECT_BEGIN',
  FILTER_PROJECT_SUCCESS: 'FILTER_PROJECT_SUCCESS',
  FILTER_PROJECT_ERR: 'FILTER_PROJECT_ERR',

  SORTING_PROJECT_BEGIN: 'SORTING_PROJECT_BEGIN',
  SORTING_PROJECT_SUCCESS: 'SORTING_PROJECT_SUCCESS',
  SORTING_PROJECT_ERR: 'SORTING_PROJECT_ERR',

  INSERT_PROJECT_BEGIN: 'INSERT_PROJECT_BEGIN',
  INSERT_PROJECT_SUCCESS: 'INSERT_PROJECT_SUCCESS',
  INSERT_PROJECT_ERR: 'INSERT_PROJECT_ERR',

  SELECT_PROJECT_SUCCESS: 'SELECT_PROJECT',
  APPROVE_ORDER_OFFER_SUCCESS: 'APPROVE_ORDER_OFFER_SUCCESS',
  REJECT_ORDER_OFFER_SUCCESS: 'REJECT_ORDER_OFFER_SUCCESS',
  REQUEST_OFFER_SAMPLE_SUCCESS: 'REQUEST_OFFER_SAMPLE_SUCCESS',

  singleProjectBegin: () => {
    return {
      type: actions.SINGLE_PROJECT_BEGIN,
    };
  },

  singleProjectSuccess: (data) => {
    return {
      type: actions.SINGLE_PROJECT_SUCCESS,
      data,
    };
  },

  singleProjectErr: (err) => {
    return {
      type: actions.SINGLE_PROJECT_ERR,
      err,
    };
  },

  filterProjectBegin: () => {
    return {
      type: actions.FILTER_PROJECT_BEGIN,
    };
  },

  filterProjectSuccess: (data) => {
    return {
      type: actions.FILTER_PROJECT_SUCCESS,
      data,
    };
  },

  filterProjectErr: (err) => {
    return {
      type: actions.FILTER_PROJECT_ERR,
      err,
    };
  },

  sortingProjectBegin: () => {
    return {
      type: actions.SORTING_PROJECT_BEGIN,
    };
  },

  sortingProjectSuccess: (data) => {
    return {
      type: actions.SORTING_PROJECT_SUCCESS,
      data,
    };
  },

  sortingProjectErr: (err) => {
    return {
      type: actions.SORTING_PROJECT_ERR,
      err,
    };
  },

  insertProjectBegin: () => {
    return {
      type: actions.INSERT_PROJECT_BEGIN,
    };
  },

  insertProjectSuccess: (data) => {
    return {
      type: actions.INSERT_PROJECT_SUCCESS,
      data,
    };
  },

  insertProjectErr: (err) => {
    return {
      type: actions.INSERT_PROJECT_ERR,
      err,
    };
  },

  selectProjectSuccess: (data) => {
    return {
      type: actions.SELECT_PROJECT_SUCCESS,
      data,
    };
  },

  approveOrderOfferSuccess: (data) => {
    return {
      type: actions.APPROVE_ORDER_OFFER_SUCCESS,
      data,
    }
  },

  rejectOrderOfferSuccess: (data) => {
    return {
      type: actions.REJECT_ORDER_OFFER_SUCCESS,
      data,
    }
  },

  requestOfferSampleSuccess: (data) => {
    return {
      type: actions.REQUEST_OFFER_SAMPLE_SUCCESS,
      data,
    }
  },
};

export default actions;
