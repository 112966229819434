import staticData from '../../demoData/projectData.json';
import actions from './actions';

const {
  SINGLE_PROJECT_BEGIN,
  SINGLE_PROJECT_SUCCESS,
  SINGLE_PROJECT_ERR,

  FILTER_PROJECT_BEGIN,
  FILTER_PROJECT_SUCCESS,
  FILTER_PROJECT_ERR,

  SORTING_PROJECT_BEGIN,
  SORTING_PROJECT_SUCCESS,
  SORTING_PROJECT_ERR,

  INSERT_PROJECT_BEGIN,
  INSERT_PROJECT_SUCCESS,
  INSERT_PROJECT_ERR,

  SELECT_PROJECT_SUCCESS,
  APPROVE_ORDER_OFFER_SUCCESS,
  REJECT_ORDER_OFFER_SUCCESS,
  REQUEST_OFFER_SAMPLE_SUCCESS,
} = actions;

const initialStateFilter = {
  selectedOrderIndex: null,
  data: staticData,
  loading: false,
  error: null,
};

const projectReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILTER_PROJECT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case FILTER_PROJECT_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case FILTER_PROJECT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case SORTING_PROJECT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case SORTING_PROJECT_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case SORTING_PROJECT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case INSERT_PROJECT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case INSERT_PROJECT_SUCCESS:
      return {
        ...initialStateFilter,
        data: [...state.data, data],
        loading: false,
      };
    case INSERT_PROJECT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };

    case SELECT_PROJECT_SUCCESS:
      return {
        ...initialStateFilter,
        selectedOrderIndex: data - 1
      };

    case APPROVE_ORDER_OFFER_SUCCESS:
      return {
        ...initialStateFilter,
        data
      };

    case REJECT_ORDER_OFFER_SUCCESS:
      return {
        ...initialStateFilter,
        data
      };

    case REQUEST_OFFER_SAMPLE_SUCCESS:
      return {
        ...initialStateFilter,
        data,
      };

    default:
      return state;
  }
};

const initialState = {
  data: staticData,
  loading: false,
  error: null,
};

const SingleProjectReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_PROJECT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SINGLE_PROJECT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case SINGLE_PROJECT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { SingleProjectReducer, projectReducer };
