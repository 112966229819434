import firebase, { storage } from 'firebase';
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';

import { getFirebase } from 'react-redux-firebase';
import { composeWithDevTools } from 'redux-devtools-extension';
import fbConfig from '../config/database/firebase';
import { createFirestoreInstance, getFirestore, reduxFirestore } from 'redux-firestore';
import rootReducer from './rootReducers';
import { persistReducer, persistStore } from 'redux-persist';
import persistStorage from 'redux-persist/lib/storage'

const reduxDevTool =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore, storage })),
        reduxFirestore(fbConfig),
      )
    : compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore, storage })),
        reduxFirestore(fbConfig),
      );

const persistConfig = {
  key: 'root',
  storage: persistStorage,
};

// const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(rootReducer, reduxDevTool);
// export const persistor = persistStore(store);

export const rrfProps = {
  firebase,
  config: (fbConfig, { useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true }),
  dispatch: store.dispatch,
  createFirestoreInstance,
};

// console.log("current state :" , rootReducer);

export default store;
