/* eslint-disable no-plusplus */

import { message } from 'antd';
import { storage } from 'firebase';
import { useCallback } from 'react';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

function partialReducer(prev, next) {
  return { ...prev, ...next };
}

function groupBy(array, groupingKey) {
  const groups = {};

  for (let index = 0; index < array.length; index++) {
    const item = array[index];
    const group = item[groupingKey];

    if (group) {
      if (!groups[group]) {
        groups[group] = [];
      }

      groups[group].push(item);
    }
  }

  return groups;
}

function cache(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function loadCache(key, defaultValue = {}) {
  const value = localStorage.getItem(key);

  if (value) {
    const localValue = JSON.parse(value);
    const keys = Object.values(defaultValue);

    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      const element = defaultValue[key];

      if (key in localValue === false) {
        localValue[key] = element;
      }
    }

    return localValue;
  }

  return defaultValue;
}

function clearCache(key) {
  localStorage.removeItem(key);
}

function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function validate(target, validations, options = { notifyMissing: false }) {
  let validationError = false;
  for (let index = 0; index < validations.length; index++) {
    const validation = validations[index];
    if (!target[validation.path]) {
      if (options.notifyMissing) {
        message.error(validation.error);
      }
      validationError = true;
    }
  }
  return !validationError;
}

const validateFilter = (filters, dataObject) => {
  const filtersKeys = Object.keys(filters);
  const comparisons = [];

  for (let index = 0; index < filtersKeys.length; index += 1) {
    const key = filtersKeys[index];

    if (key === '$or') {
      let orCheck = false;
      for (let oIndex = 0; oIndex < filters[key].length; oIndex += 1) {
        const orFilter = filters[key][oIndex];
        const valid = validateFilter(orFilter, dataObject);

        if (valid) {
          comparisons.push(true);
          orCheck = true;
          break;
        }
      }

      if (!orCheck) {
        comparisons.push(false);
      }
    } else {
      const sanitizedFilter = filters[key].replace(/(\[|\]|\(|\)|\$)/g, '\\$1');
      const pattern = new RegExp(sanitizedFilter, 'i');
      comparisons.push(pattern.test(dataObject[key]));
    }
  }

  return comparisons.every((check) => check);
};

function normalizeFiles(files) {
  const normalized = {};
  const keys = Object.keys(files);

  for (let index = 0; index < keys.length; index += 1) {
    const fileName = keys[index];
    const file = files[fileName];

    if (file) {
      normalized[fileName] = {
        name: file.name,
        url: file.url,
        thumbUrl: file.thumbUrl,
      };
    }
  }

  return normalized;
}

const uploadFile = (file) => {
  return new Promise((resolve, reject) => {
    const uploadTask = storage().ref(`/images/${file.name}`).put(file);

    uploadTask.on(
      'state_changed',
      (snapShot) => {
        // takes a snap shot of the process as it is happening
        // onProgress({ percent: (snapShot.bytesTransferred / snapShot.totalBytes) * 100 });
      },
      (err) => {
        // catches the errors
        console.log(err);
        reject(err);
        // onError(err);
      },
      () => {
        storage()
          .ref('images')
          .child(file.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            resolve(fireBaseUrl);
          });
      },
    );
  });
};

export {
  uploadFile,
  ellipsis,
  partialReducer,
  groupBy,
  cache,
  loadCache,
  clearCache,
  randomBetween,
  validate,
  validateFilter,
  normalizeFiles,
};
