import { Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import config from '../config/config';

const { SubMenu } = Menu;

function MenuItems({ darkMode, toggleCollapsed, topMenu }) {
  const { path } = useRouteMatch();
  const email = useSelector((state) => state.fb.auth.email);
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  // const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const getSelectedKeys = () => {
    const lastToken = mainPathSplit[mainPathSplit.length - 1];

    if (mainPath === '') {
      return ['dashboard', 'admin-orders'];
    }

    if (['offers', 'details'].includes(lastToken)) {
      return ['view'];
    }

    if (mainPathSplit.length > 2) {
      return [mainPathSplit[2]];
    }

    return [lastToken];
  }

  const renderUserMenu = () => {
    return (
      <>
        {/* Dashboard single */}
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path}`}>
                <FeatherIcon icon="home" />
              </NavLink>
            )
          }
          key="dashboard"
        >
          <NavLink onClick={toggleCollapsed} to={`${path}`}>
            Dashboard
          </NavLink>
        </Menu.Item>

        {/* 2 ================================================================== file */}
        <SubMenu key="Orders" icon={!topMenu && <FeatherIcon icon="shopping-cart" />} title="Order">
          <Menu.Item key="new">
            <NavLink onClick={toggleCollapsed} to={`${path}/orders/new/select-category`}>
              New Order
            </NavLink>
          </Menu.Item>
          <Menu.Item key="view">
            <NavLink active onClick={toggleCollapsed} to={`${path}/project/view`}>
              Orders
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item key="view">
          <NavLink onClick={toggleCollapsed} to={`${path}/project/view/grid`}>
            Orders Grid
          </NavLink>
        </Menu.Item> */}
          {/* <Menu.Item key="ProjectCreate">
          <NavLink onClick={toggleCollapsed} to={`${path}/project/create/order`}>
            Create Order
          </NavLink>
        </Menu.Item> */}
          {/* <Menu.Item key="projectDetails">
          <NavLink onClick={toggleCollapsed} to={`${path}/project/projectDetails/1`}>
            Order Details
          </NavLink>
        </Menu.Item> */}
        </SubMenu>

        {/* 3 ================================================================== */}
        {/* <SubMenu key="requests" icon={!topMenu && <FeatherIcon icon="bell" />} title="Requests">
        <Menu.Item key="contact-grid">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/grid`}>
            Contact Grid
          </NavLink>
        </Menu.Item>
        <Menu.Item key="contact-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/list`}>
            Contact List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="addNew">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/addNew`}>
            Contact Create
          </NavLink>
        </Menu.Item>
      </SubMenu> */}
        {/* 4 ================================================================== */}
        {/* <SubMenu key="purchase-orders" icon={!topMenu && <FeatherIcon icon="file-text" />} title="Purchase Orders">
        <Menu.Item key="contact-grid">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/grid`}>
            Contact Grid
          </NavLink>
        </Menu.Item>
        <Menu.Item key="contact-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/list`}>
            Contact List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="addNew">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/addNew`}>
            Contact Create
          </NavLink>
        </Menu.Item>
      </SubMenu> */}
        {/* 5 ================================================================== */}
        {/* <SubMenu key="deliveries" icon={!topMenu && <FeatherIcon icon="codesandbox" />} title="Deliveries">
        <Menu.Item key="contact-grid">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/grid`}>
            Contact Grid
          </NavLink>
        </Menu.Item>
        <Menu.Item key="contact-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/list`}>
            Contact List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="addNew">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/addNew`}>
            Contact Create
          </NavLink>
        </Menu.Item>
      </SubMenu> */}
        {/* 6 ================================================================== */}
        {/* <SubMenu key="invoices" icon={!topMenu && <FeatherIcon icon="file" />} title="Invoices">
        <Menu.Item key="contact-grid">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/grid`}>
            Contact Grid
          </NavLink>
        </Menu.Item>
        <Menu.Item key="contact-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/list`}>
            Contact List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="addNew">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/addNew`}>
            Contact Create
          </NavLink>
        </Menu.Item>
      </SubMenu> */}
        {/* 7 ================================================================== */}
        {/* <SubMenu key="payments" icon={!topMenu && <FeatherIcon icon="dollar-sign" />} title="Payments">
        <Menu.Item key="contact-grid">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/grid`}>
            Contact Grid
          </NavLink>
        </Menu.Item>
        <Menu.Item key="contact-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/list`}>
            Contact List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="addNew">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/addNew`}>
            Contact Create
          </NavLink>
        </Menu.Item>
      </SubMenu> */}
        {/* 8 ================================================================== */}
        {/* <SubMenu key="Budget" icon={!topMenu && <FeatherIcon icon="briefcase" />} title="Budget">
        <Menu.Item key="contact-grid">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/grid`}>
            Contact Grid
          </NavLink>
        </Menu.Item>
        <Menu.Item key="contact-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/list`}>
            Contact List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="addNew">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/addNew`}>
            Contact Create
          </NavLink>
        </Menu.Item>
      </SubMenu> */}
        {/* 9 ================================================================== */}
        {/* <SubMenu key="suppliers" icon={!topMenu && <FeatherIcon icon="truck" />} title="Suppliers">
        <Menu.Item key="contact-grid">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/grid`}>
            Contact Grid
          </NavLink>
        </Menu.Item>
        <Menu.Item key="contact-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/list`}>
            Contact List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="addNew">
          <NavLink onClick={toggleCollapsed} to={`${path}/contact/addNew`}>
            Contact Create
          </NavLink>
        </Menu.Item>
      </SubMenu> */}
      </>
    );
  };

  const renderAdminMenu = () => {
    return (
      <Menu.Item icon={<FeatherIcon icon="shopping-cart" />} key="admin-orders">
        <NavLink onClick={toggleCollapsed} to={`${path}/admin-orders`}>
          Orders
        </NavLink>
      </Menu.Item>
    );
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      selectedKeys={getSelectedKeys()}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {email === config.adminEmail ? renderAdminMenu() : renderUserMenu()}
    </Menu>
  );
}

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  // events: propTypes.object,
};

export default MenuItems;
