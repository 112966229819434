const actions = {
  UPDATE_NEW_ORDER_STATE: 'UPDATE_NEW_ORDER_STATE',

  updateNewOrder: (data, options) => {
    return {
      type: actions.UPDATE_NEW_ORDER_STATE,
      data,
      options
    };
  },
};

export default actions;
