import { login } from '../../authentication/actionCreator';
import actions from './actions';
import Cookies from 'js-cookie';

const {
  fbLoginBegin,
  fbLoginSuccess,
  fbLoginErr,
  fbLogOutBegin,
  fbLogOutSuccess,
  fbLogOutErr,
  fbSignUpBegin,
  fbSignUpSuccess,
  fbSignUpErr,
} = actions;

const fbAuthLogin = (data, history) => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
      await dispatch(fbLoginBegin());
      // const fbLoginResult = await fb.auth().signInWithEmailAndPassword(data.email, data.password);
       fb.auth().signInWithEmailAndPassword(data.email, data.password).then(async (userCredential) => {
        // Signed in 
        // console.log("fblogin success: ", userCredential.user)
        const uCredential =  await userCredential.user;
        console.log("fblogin result: start", uCredential.email)
         await dispatch(fbLoginSuccess(uCredential));
         await dispatch(login());
         history.push('/admin');
      })
      .catch(async (error) => {
        const errorCode = error.code;
        const errorMessage = JSON.parse(error.message).error.message;
         await dispatch(fbLoginErr(errorMessage));
      });
  };
};

const fbAuthLogout = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
      await dispatch(fbLogOutBegin());
      fb.auth().signOut().then(async () => {
     Cookies.remove('logedIn');
      await dispatch(fbLogOutSuccess());
    }).catch(async (error) => {
      Cookies.remove('logedIn');
      await dispatch(fbLogOutErr(error));
    });
  };
};

const fbAuthSignUp = newUser => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const fb = getFirebase();
    try {
      await dispatch(fbSignUpBegin());
      const resp = await fb.auth().createUserWithEmailAndPassword(newUser.email, newUser.password);
      await db
        .collection('users')
        .doc(resp.user.uid)
        .set({
          ...newUser,
        });
      await dispatch(fbSignUpSuccess());
    } catch (err) {
      await dispatch(fbSignUpErr(err));
    }
  };
};

const fbAuthLoginWithGoogle = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    const provider = new fb.auth.GoogleAuthProvider();
    try {
      await dispatch(fbLoginBegin());
      const result = await fb.auth().signInWithPopup(provider);
      await dispatch(fbLoginSuccess(result));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

const fbAuthLoginWithFacebook = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    const provider = new fb.auth.FacebookAuthProvider();
    try {
      await dispatch(fbLoginBegin());
      const result = await fb.auth().signInWithPopup(provider);
      await dispatch(fbLoginSuccess(result));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

export { fbAuthLogin, fbAuthLogout, fbAuthSignUp, fbAuthLoginWithGoogle, fbAuthLoginWithFacebook };
