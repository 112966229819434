import moment from 'moment';
import { cache, loadCache } from '../../utility/utility';
import actions from './actions';

const SELECTED_CATEGORY_CACHE = 'selected_categpry_cache';
export const NEW_ORDER_INITIAL_STATE = {
  title: 'Linen Request',
  status: 'bidding',
  content: '',
  category: '',
  design: true,
  startdate: moment().toISOString(),
  enddate: null,
  rate: 5,
  popular: 2,
  percentage: 1,
  orderProducts: [],
  offers: [],
  expectedDelivery: null,

  // -- helpers
  files: {},
  loaders: {},
  categories: [],
  specifications: {},
  skipableCategories: [4, 5, 6],
  selectedProductsMap: '',
  duration: null,
};

const initialState = {
  ...NEW_ORDER_INITIAL_STATE,
  category: loadCache(SELECTED_CATEGORY_CACHE, ''),
}

// loadCache(NEW_ORDER_CACHE, );

const { UPDATE_NEW_ORDER_STATE } = actions;

const newOrdersReducer = (state = initialState, action) => {
  const { type, data, options, err } = action;
  switch (type) {
    case UPDATE_NEW_ORDER_STATE: {
      let newState = {
        ...state,
        ...data,
      };

      if (options?.merge) {
        newState = {
          ...state,
        };

        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (typeof value === 'object') {
            newState[key] = {
              ...state[key],
              ...value,
            };
          } else {
            newState[key] = value;
          }
        });
      }

      if (data.category) {
        cache(SELECTED_CATEGORY_CACHE, newState.category);
      }
      return newState;
    }
    default:
      return state;
  }
};

export default newOrdersReducer;
