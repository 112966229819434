const initialState = [
  {
    name: 'customer1',
    email: 'customer1@gmail.com',
    mobile: '055742920502',
    id: 1,
  },
];

const themeUsersReducer = (state = initialState) => {
  return state;
};
export default themeUsersReducer;
